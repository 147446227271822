import Head from 'next/head';
import configuration from '~/configuration';
import { useContext } from 'react';
import { ThemeContext } from '~/core/contexts/theme';

const FAVICON = (function favicon(stage?: string | null) {
  switch (stage) {
    case null:
    case undefined:
    case '':
    case 'prd':
    case 'prod':
    case 'production':
      return 'favicon';
    case 'stg':
    case 'staging':
    case 'preview':
      return 'favicon-preview';
    default:
      return 'favicon-development';
  }
})(process.env.NEXT_PUBLIC_STAGE)

const Layout: React.FCC = ({ children }) => {
  const siteUrl = configuration.site.siteUrl;

  if (!siteUrl) {
    throw new Error(`Please add the property siteUrl in the configuration`);
  }

  const structuredData = {
    name: configuration.site.name,
    url: siteUrl,
    logo: `${siteUrl}/assets/images/${FAVICON}/favicon-150x150.png`,
    '@context': 'https://schema.org',
    '@type': 'Organization', // change to person for Personal websites
  };

  return (
    <>
      <Head>
        <title key={'title'}>{configuration.site.name}</title>

        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href={`/assets/images/${FAVICON}/apple-icon-57x57.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href={`/assets/images/${FAVICON}/apple-icon-60x60.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href={`/assets/images/${FAVICON}/apple-icon-72x72.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href={`/assets/images/${FAVICON}/apple-icon-76x76.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href={`/assets/images/${FAVICON}/apple-icon-114x114.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href={`/assets/images/${FAVICON}/apple-icon-120x120.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href={`/assets/images/${FAVICON}/apple-icon-144x144.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href={`/assets/images/${FAVICON}/apple-icon-152x152.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`/assets/images/${FAVICON}/apple-icon-180x180.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={`/assets/images/${FAVICON}/android-icon-192x192.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`/assets/images/${FAVICON}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href={`/assets/images/${FAVICON}/favicon-96x96.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`/assets/images/${FAVICON}/favicon-16x16.png`}
        />
        <link rel="manifest" href={`/assets/images/${FAVICON}/manifest.json`} />

        <link rel="shortcut icon" href={`/assets/images/${FAVICON}/favicon.ico`} />

        {/* <link rel="mask-icon" href={`/assets/images/${FAVICON}/safari-pinned-tab.svg`} color="#000000" /> */}

        <link rel="dns-prefetch" href="//fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-config" content={`/assets/images/${FAVICON}/browserconfig.xml`} />

        <MetaColor />

        <meta name="description" content={configuration.site.description} key="meta:description" />

        <meta property="og:title" key="og:title" content={configuration.site.name} />

        <meta
          property="og:description"
          key="og:description"
          content={configuration.site.description}
        />

        <meta property="og:site_name" content={configuration.site.siteName} />
        <meta property="twitter:title" content={configuration.site.siteName} />
        <meta property="twitter:card" content="summary_large_image" />

        <meta property="twitter:creator" content={configuration.site.twitterHandle} />

        <script
          async
          key="ld:json"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
        />
      </Head>

      <main>{children}</main>
    </>
  );
};

export default Layout;

function MetaColor() {
  const { theme } = useContext(ThemeContext);

  const color =
    theme === 'dark' ? configuration.site.themeColorDark : configuration.site.themeColor;

  return <meta name="theme-color" content={color} />;
}
