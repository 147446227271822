import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

import Container from '~/core/ui/Container';
import LogoImage from '~/core/ui/Logo/LogoImage';
import configuration from '~/configuration';

const YEAR = new Date().getFullYear();

function Footer() {
  return (
    <footer className={'py-8 lg:py-12'}>
      <Container>
        <div className={'flex flex-col space-y-8 lg:flex-row lg:space-y-0 gap-4'}>
          <div
            className={twMerge(
              'flex w-full space-x-2 lg:w-4/12 xl:w-3/12', 
              'xl:space-x-6 2xl:space-x-5', 
              'order-last lg:order-first'
            )}
          >
            <div className={'flex flex-col space-y-4'}>
              <div>
                <LogoImage className={'w-[85px] md:w-[115px]'} />
              </div>

              <div>
                <p className={'text-sm text-gray-500 dark:text-gray-400'}>
                  Design. Automate. Optimize
                </p>
              </div>

              <div className={'flex text-xs text-gray-500 dark:text-gray-400'}>
                <p>
                  © Copyright {YEAR} {configuration.site.siteName}. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>

          <div
            className={twMerge(
              'flex flex-col space-y-4 w-full',
              'lg:space-y-0 lg:space-x-6 lg:flex-row lg:justify-end',
              'xl:space-x-16 2xl:space-x-20'
          )}
          >
              <div className={'flex flex-col'}>
                <FooterSectionHeading>About</FooterSectionHeading>
                <FooterSectionList>
                  {/* <FooterLink>
                    <Link href={'#'}>Who we are</Link>
                  </FooterLink>
                  <FooterLink>
                    <Link href={'/blog'}>Blog</Link>
                  </FooterLink> */}
                  <FooterLink>
                    <Link href={'/contact'}>Contact</Link>
                  </FooterLink>
                </FooterSectionList>
              </div>
           
            
              {/* <div className={'flex flex-col space-y-4'}>
                <FooterSectionHeading>Product</FooterSectionHeading>

                <FooterSectionList>
                  <FooterLink>
                    <Link href={'/docs'}>Documentation</Link>
                  </FooterLink>
                  <FooterLink>
                    <Link href={'#'}>Help Center</Link>
                  </FooterLink>
                  <FooterLink>
                    <Link href={'#'}>Changelog</Link>
                  </FooterLink>
                </FooterSectionList>
            </div> */}

              <div className={'flex flex-col mt-0'}>
                <FooterSectionHeading>Legal</FooterSectionHeading>
                <FooterSectionList>
                  <FooterLink>
                    <Link href={'terms'}>Terms of Service</Link>
                  </FooterLink>
                  {/* <FooterLink>
                    <Link href={'#'}>Privacy Policy</Link>
                  </FooterLink>
                  <FooterLink>
                    <Link href={'#'}>Cookie Policy</Link>
                  </FooterLink> */}
                </FooterSectionList>
              </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}

function FooterSectionHeading(props: React.PropsWithChildren) {
  return (
    <p>
      <span className={'font-semibold'}>{props.children}</span>
    </p>
  );
}

function FooterSectionList(props: React.PropsWithChildren) {
  return (
    <ul className={'flex flex-col text-gray-500 dark:text-gray-400 mt-2'}>{props.children}</ul>
  );
}

function FooterLink(props: React.PropsWithChildren) {
  return (
    <li
      className={twMerge(
        'text-sm [&>a]:transition-colors [&>a]:hover:text-gray-800', 
        'dark:[&>a]:hover:text-white'
      )
      }
    >
      {props.children}
    </li>
  );
}

export default Footer;
